import Empty from "@/components/Empty";
import Loader from "@/components/Loader";
import { ActivityTypeEnum, AlbumTypeEnum } from "@/enums";
import bannerModel from "@/models/banner.model";
import planModel from "@/models/plan.model";
import { useInfiniteScroll, useReactive, useRequest } from "ahooks";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Image,
  List,
  PullRefresh,
  Search,
  Sticky,
  Swiper,
  Tabs,
} from "react-vant";
import styles from "./index.module.css";

interface ListViewProps {
  type: AlbumTypeEnum;
  keyword?: string;
  onClick?: (item: any) => void;
}

const ListView = ({ type, keyword, onClick }: ListViewProps) => {
  const { data, loading, reloadAsync, loadMoreAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return planModel
          .list({
            params: {
              type,
              keyword,
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [type, keyword],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item: any) => (
            <div
              key={item.id}
              className={styles.item}
              onClick={() => onClick && onClick(item)}
            >
              {item.isSoldOut ? (
                <div className={styles.status}>
                  <Image
                    className={styles.icon}
                    src={"/images/plan/status-3.png"}
                  />
                </div>
              ) : dayjs(item.startTime).isAfter(dayjs()) ? (
                <div className={styles.status}>
                  <Image
                    className={styles.icon}
                    src={"/images/plan/status-1.png"}
                  />
                </div>
              ) : (
                <div className={styles.status}>
                  <Image
                    className={styles.icon}
                    src={"/images/plan/status-2.png"}
                  />
                </div>
              )}
              <Image className={styles.image} src={item.image} />
              <div className={styles.title}>{item.title}</div>
              <div className={styles.publisher}>
                发行方: {item.publisherName}
              </div>
              <div className={styles.footer}>
                <div className={styles.tags}>
                  <div className={styles.limit}>
                    <span className={styles.label}>限量</span>
                    <span className={styles.num}>{item?.limit}份</span>
                  </div>
                </div>
                <div className={styles.price}>
                  ¥<span>{item.price}</span>
                </div>
              </div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  const nav = useNavigate();
  const state = useReactive({
    tab: AlbumTypeEnum.ASSET,
    keyword: "",
    scrollTop: 0,
    isActive: true,
  });
  const { data: banners } = useRequest(() => {
    return bannerModel
      .list({ params: { type: 1, current: 1, pageSize: 10 } })
      .then((res) => res.data);
  });
  const tabs = useMemo(() => {
    return [
      {
        key: AlbumTypeEnum.ASSET,
        title: "数字资产",
      },
      {
        key: AlbumTypeEnum.BLIND_BOX,
        title: "盲盒发售",
      },
    ];
  }, []);
  return (
    <div className={styles.page}>
      <Sticky>
        <div className={styles.header}>
          <div className={styles.slogan} />
          <Search
            className={styles.search}
            placeholder="搜索资产、盲盒"
            clearable
            leftIcon={
              <Image
                className={styles.icon}
                src={require("@/assets/icons/search.png").default}
              />
            }
            value={state.keyword}
            onSearch={(e) => {
              state.keyword = e;
            }}
          />
        </div>
      </Sticky>
      <div className={styles.actions}>
        <div
          className={styles.item}
          onClick={() => nav(`/activity/${ActivityTypeEnum.COMPOSE}`)}
        >
          <Image className={styles.image} src={"/images/home/action-1.png"} />
          <div className={styles.text}>合成</div>
        </div>
        <div
          className={styles.item}
          onClick={() => nav(`/activity/${ActivityTypeEnum.EXCHANGE}`)}
        >
          <Image className={styles.image} src={"/images/home/action-2.png"} />
          <div className={styles.text}>置换</div>
        </div>
        <div
          className={styles.item}
          onClick={() => nav(`/activity/${ActivityTypeEnum.DECOMPOSE}`)}
        >
          <Image className={styles.image} src={"/images/home/action-3.png"} />
          <div className={styles.text}>分解</div>
        </div>
        <div
          className={styles.item}
          onClick={() => nav(`/activity/${ActivityTypeEnum.LOTTERY}`)}
        >
          <Image className={styles.image} src={"/images/home/action-4.png"} />
          <div className={styles.text}>抽奖</div>
        </div>
        <div className={styles.item} onClick={() => nav("/invite/detail")}>
          <Image className={styles.image} src={"/images/home/action-5.png"} />
          <div className={styles.text}>邀请</div>
        </div>
      </div>
      <div className={styles.banners}>
        <Swiper loop={false} autoplay={5000} slideSize={80}>
          {banners?.list?.length > 0 ? (
            banners?.list?.map((item: any) => (
              <Swiper.Item key={item.id}>
                <Image className={styles.banner} fit="cover" src={item.image} />
              </Swiper.Item>
            ))
          ) : (
            <Swiper.Item>
              <Image className={styles.banner} />
            </Swiper.Item>
          )}
        </Swiper>
      </div>
      <Tabs
        className={styles.tabs}
        type="line"
        align="start"
        active={state.tab}
        onChange={(key) => {
          state.tab = key as AlbumTypeEnum;
        }}
      >
        {tabs.map((item) => (
          <Tabs.TabPane key={item.key} name={item.key} title={item.title}>
            <ListView
              type={item.key}
              onClick={(item) => {
                nav(`/plan/detail/${item.id}`);
              }}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
