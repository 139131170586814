import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  list: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/banner/list`, {
      data,
    });
  },
};

export default {
  ...actions,
};
